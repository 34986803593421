<template>
	<form @submit.prevent="handleSubmit">
		<div class="row">
			<div class="form-group col-sm-12 col-md-12 required">
				<label for="part_name">Part Name:</label>
				<input
					class="form-control"
					name="part_name"
					type="text"
					v-model="part.part_name"
					required
				/>
			</div>
			<div class="form-group col-sm-12 col-md-12 required">
				<label for="part_code">Part Code:</label>
				<input
					class="form-control"
					name="part_code"
					type="text"
					v-model="part.part_code"
					required
				/>
			</div>
			<div class="form-group col-sm-12 col-md-12 required">
				<label for="uom">UOM:</label>
				<input
					class="form-control"
					name="uom"
					type="text"
					v-model="part.uom"
					required
				/>
			</div>
			<div class="form-group col-sm-12 col-md-12">
				<label for="brand">Brand:</label>
				<input
					class="form-control"
					name="brand"
					type="text"
					v-model="part.brand"
				/>
			</div>
			<div class="form-group col-sm-12 col-md-12">
				<label for="sku_number">SKU Number:</label>
				<input
					class="form-control"
					name="sku_number"
					type="text"
					v-model="part.sku_number"
				/>
			</div>
			<div class="form-group col-sm-12">
				<input class="btn btn-primary" type="submit" value="Save" />
				<router-link class="btn btn-default ml-1" to="/news"
					>Cancel</router-link
				>
			</div>
		</div>
		<div
			v-if="this.onRequest"
			class="modal-backdrop fade show"
			style="display:flex; align-items: center; justify-content: center;"
		>
			<img :src="require('../assets/loading.gif')" />
		</div>
	</form>
</template>

<script>
	import { mapState, mapActions } from "vuex";
	export default {
		computed: {
			...mapState("parts", ["part", "onRequest"]),
			...mapState("users", ["user"]),
		},
		methods: {
			handleSubmit() {
				if (this.onRequest) return;
				this.clear();
				this.edit(this.part);
			},
			...mapActions("parts", ["edit", "get"]),
			...mapActions("alert", ["error", "clear"]),
		},
		created() {
			this.$emit("onChildInit", "Edit Cost");
			this.get(this.$route.params.id);
		},
	};
</script>
