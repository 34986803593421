<template>
	<div class="row">
		<div class="col-sm-12">
			<Datatable
				v-bind:entries="news"
				:columns="columns"
				:currEntries="50"
				:title="'List News'"
			/>
		</div>
	</div>
</template>

<script>
	import { mapState, mapActions } from "vuex";
	import { getCapability } from "../_helpers";
	import Datatable from "../components/Datatable.vue";
	export default {
		computed: {
			...mapState("news", ["news"]),
			...mapState("users", ["user", "u"]),
			columns() {
				function showing(is_show, obj) {
					if (is_show) {
						return obj;
					}
					return {};
				}
				return [
					{ name: "title", text: "Judul", order: "asc" },
					{ name: "content", text: "Konten", order: "asc" },
					{ name: "created_by", text: "Dibuat Oleh" },
					{ name: "created_at", text: "Dibuat Tanggal" },
					{
						name: "action",
						text: "Action",
						raw: {
							type: "Action",
							fields: [
								showing(getCapability("edit_news", this.user), {
									event: "edit_news",
									text: "Edit",
									method: "get",
									class: "btn btn-primary",
								}),
								showing(getCapability("delete_news", this.user), {
									event: "news/destroy",
									text: "Delete",
									method: "post",
									class: "btn btn-danger ml-1",
								}),
							],
						},
					},
				];
			},
		},
		components: {
			Datatable,
		},
		data: () => {
			return {
				last_date_news: null,
			};
		},
		methods: {
			handleSubmit() {
				let uaccount = null;
				if (this.user.account !== null) uaccount = this.user.account.id;
				this.get_all(uaccount, this.last_date_news);
			},
			...mapActions("news", ["get_all"]),
			...mapActions("alert", ["error", "clear"]),
		},
		created() {
			this.$emit(
				"onChildInit",
				"News",
				getCapability("add_news", this.user) ? "/news/add" : ""
			);
			// this.get_all(this.user.account.id, this.last_date_news);
			this.handleSubmit();
		},
	};
</script>
